import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import useSound from 'use-sound'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import styles from './Screen1.module.scss'
import video from '../../assets/video/Page-1.webm'
import videoD from '../../assets/video/Page-1_a.webm'
import poster from '../../assets/video/Page_1.gif'
import posterD from '../../assets/video/Page_1_a.gif'
import sound from '../../assets/sounds/sound-screen-1.MP3'
import { ReactComponent as Sound } from '../../assets/images/sound.svg'
import Visual from '../../components/Visual/Visual'
import useIsIphone from '../../utils/useIsIphone'
import Video from '../../components/Video/Video'

const Screen1 = ({ difficulty, setDifficulty, setKey }) => {
  const [srcVideo, setSrcVideo] = useState(video)
  const [srcPoster, setSrcPoster] = useState(poster)
  const [isPlaying, setIsPlaying] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 1279px)' })
  const [play, { stop, pause }] = useSound(sound, {
    onplay: () => setIsPlaying(true),
    onend: () => setIsPlaying(false),
    volume: 0.5,
  })

  const isIphone = useIsIphone()

  function setSrc(srcV, srcP) {
    setSrcVideo(srcV)
    setSrcPoster(srcP)
  }

  useEffect(() => {
    difficulty.value === 'daltonizm' ? setSrc(videoD, posterD) : setSrc(video, poster)
    difficulty.value === 'visual' ? play() : stop()
  }, [difficulty, play, stop])
  useEffect(() => {
    return () => {
      stop()
    }
  }, [stop])
  return (
    <div className="main-wrap main-wrap__dark">
      {isMobile ? (
        <Header
          showDifficulty={true}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          link={''}
          isModalStory={true}
          setKey={setKey}
        />
      ) : (
        <Header
          showDifficulty={true}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          link={'about'}
          isModalStory={true}
          setKey={setKey}
        />
      )}
      <div className="content">
        <div className="container">
          <div className={styles.wrap}>
            <div className={styles.videoBlock}>
              {isIphone ? (
                <img src={srcPoster} alt="Очень важная история" />
              ) : (
                <Video key={srcVideo} srcPoster={srcPoster} srcVideo={srcVideo} />
              )}
            </div>
            <div className={`dyslexia-text ${styles.text} text-position-1`}>
              <p>
                <span className={`${styles.first} daltonizm-color`}>М</span>
                ожет, в следующей жизни я&nbsp;увижу&nbsp;больше?
              </p>
              <p>
                Звуки панельки меня не&nbsp;отпускают. И&nbsp;никогда не&nbsp;отпустят. Разве что
                взять нож,&nbsp;не&nbsp;буквально, конечно, и&nbsp;перерезать пуповину.{' '}
              </p>
              <p>
                Что тут держит? На&nbsp;этих улицах, в&nbsp;этих домах невозможно дышать. Можно
                свободно вздохнуть, лишь выехав в&nbsp;лес, но&nbsp;и&nbsp;леса скоро
                не&nbsp;станет. Шуршащие угольки да&nbsp;першащий смог в&nbsp;горле. Бесконечные
                глыбы, я&nbsp;их&nbsp;не&nbsp;вижу, но&nbsp;чувствую&nbsp;за&nbsp;плечами.
              </p>
            </div>
          </div>
        </div>
      </div>
      {difficulty.value === 'visual' && (
        <Visual
          isPlaying={isPlaying}
          pause={pause}
          play={play}
          setIsPlaying={setIsPlaying}
          sound={<Sound />}
        />
      )}
      <Footer
        linkLeft={{ text: 'Назад', link: '' }}
        linkRight={{ text: 'Дальше', link: '/screen-2' }}
        currentStep={1}
        maxStep={7}
      />
    </div>
  )
}

export default Screen1
