import React from 'react'
import { Link } from 'react-router-dom'

const Share = () => {
  const url = 'https://story.palindrome.media/'
  const text = ''
  return (
    <div className="share">
      Рассказать о проекте:
      <div>
        <Link
          to={`https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(
            text,
          )}`}
          className="social-link daltonizm-color"
          target="_blank"
          rel="noopener noreferrer">
          TG
        </Link>
        <Link
          to={`https://vk.com/share.php?url=${encodeURIComponent(url)}&title=${encodeURIComponent(
            text,
          )}`}
          className="social-link daltonizm-color"
          target="_blank"
          rel="noopener noreferrer">
          VK
        </Link>
      </div>
    </div>
  )
}

export default Share
