import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../components/Header/Header'
import styles from './Home.module.scss'
import videoRight from '../../assets/video/Page-0.webm'
import poster from '../../assets/video/Page_0.gif'
import imgLeftDark from '../../assets/images/home/img-left-dark.png'
import imgLeftLight from '../../assets/images/home/img-left-light.png'
import useIsIphone from '../../utils/useIsIphone'
import Video from '../../components/Video/Video'

const Home = ({ setDifficulty }) => {
  const [openHomeModal, setOpenHomeModal] = useState(false)
  const [showImg, setShowImg] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 1279px)' })
  const location = useLocation()
  const navigate = useNavigate()
  let { isFromAbout } = location.state || false
  const isIphone = useIsIphone()

  useEffect(() => {
    setOpenHomeModal(false)
  }, [openHomeModal])

  useEffect(() => {
    if (isFromAbout) {
      setOpenHomeModal(true)
      navigate(location.pathname, { replace: true, state: null })
    }
  }, [location, navigate, isFromAbout])

  useEffect(() => {
    setTimeout(() => {
      isMobile && setShowImg(true)
    }, 3000)
  }, [])

  return (
    <div className="main-wrap main-wrap__dark home-wrap">
      <Helmet>
        <title>Очень важная история — спецпроект о цифровой доступности</title>
        <meta
          name="description"
          content="Погрузитесь в мир человека с ограниченными возможностями. Ведь удобства в цифровой среде важны так же, как и удобства в реальной."
        />
      </Helmet>
      <div className={styles.video + ' ' + styles.videoRight}>
        {isIphone ? (
          <img src={poster} alt="Очень важная история" />
        ) : (
          <Video key={videoRight} srcPoster={poster} srcVideo={videoRight} />
        )}
      </div>
      <div className={styles.imgLeft}>
        {showImg ? (
          <img src={imgLeftLight} alt="Очень важная история" />
        ) : (
          <img src={imgLeftDark} alt="Очень важная история" />
        )}
      </div>
      <Header
        showDifficulty={false}
        difficulty={{
          title: 'без ограничений',
          value: 'not-choise',
          description: '',
        }}
        setDifficulty={setDifficulty}
        openHomeModal={openHomeModal}
        link={'about'}
      />

      <div className={`content ${styles.content}`}>
        <h1 className={styles.title}>
          очень важная
          <br />
          <span>Иcтория</span>
        </h1>
        <div className={styles.description}>
          Мир для меня выглядит иначе,
          <br className={styles.mb} /> ведь мои возможности здоровья
          <br className={styles.mb} /> ограничены. Пойдём&nbsp;со&nbsp;мной&nbsp;—
          <br className={styles.mb} /> я&nbsp;расскажу, как это чувствуется.
        </div>
        <div
          className={styles.read}
          onClick={() => {
            setOpenHomeModal(true)
          }}
          onMouseEnter={() => setShowImg(true)}
          onMouseLeave={() => setShowImg(false)}
          aria-haspopup="menu">
          <svg
            width="364"
            height="79"
            viewBox="0 0 364 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M364 39.7296C364 61.0686 308.13 84.8068 202.316 77.7233C101.8 77.7233 0 61.0686 0 39.7296C0 18.3906 62.8609 0.447974 163.377 0.447974C275.963 -3.41601 364 18.3906 364 39.7296Z"
              fill="white"
            />
          </svg>
          Читать
        </div>
      </div>
    </div>
  )
}

export default Home
